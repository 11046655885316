import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';

import { useAuthContext } from '../../auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),

  // new icons from here on down
  profile: icon('profile'),
  chart: icon('chart'),
  home: icon('home'),
  list: icon('list'),
  matching: icon('matching'),
  usage: icon('usage'),
  barGraph: icon('barGraph'),
  funnel: icon('funnel'),
  arrowRight: icon('arrowRight'),
  editNote: icon('editNote'),
  peopleOutline: icon('peopleOutline'),
};

const practiceFlowEnabled = import.meta.env.VITE_PRACTICE_FLOW === 'ON';
const providerFlowEnabled = import.meta.env.VITE_PROVIDER_FLOW === 'ON';
const adminFlowEnabled = import.meta.env.VITE_ADMIN_FLOW === 'ON';
const clientIntakeFlowEnabled = import.meta.env.VITE_CLIENT_INTAKE_FLOW === 'ON';
const clientReferralFlowEnabled = import.meta.env.VITE_CLIENT_REFERRAL === 'ON';
const groupFlowEnabled = import.meta.env.VITE_GROUP_FLOW === 'ON';
const practiceRosterFlowEnabled = import.meta.env.VITE_PRACTICE_ROSTER_FLOW === 'ON';
const clientMatchingFlowEnabled = import.meta.env.VITE_CLIENT_MATCHING_FLOW === 'ON';

function buildAdminSection() {
  const adminSection = {
    subheader: 'Admins',
    roles: ['biller', 'scheduler', 'practice_manager', 'clinician_admin'],
    items: [
      {
        title: 'Provider Roster',
        path: paths.dashboard.admin.providerRoster,
        icon: ICONS.list,
      }
    ],
  };

  if(practiceRosterFlowEnabled) {
    adminSection.items.push(
      {
        title: 'Practice Roster',
        path: paths.dashboard.admin.practiceRoster,
        icon: ICONS.list,
      }
    )
  }

  if(groupFlowEnabled) {
    adminSection.items.push(
      {
        title: 'Group Directory',
        path: paths.dashboard.admin.groupsRoster,
        icon: ICONS.list,
      }
    )
  }

  if (clientIntakeFlowEnabled) {
    adminSection.items.push(
      {
        title: 'Client Intake',
        path: paths.dashboard.clientIntake,
        icon: ICONS.funnel,
      }
    );
  }

  if (clientMatchingFlowEnabled)
    adminSection.items.push(
      {
        title: 'Client Provider Matching',
        path: paths.dashboard.admin.clientMatching,
        icon: ICONS.matching,
      }
    );

  return adminSection;
}

function buildProviderSection(user: Record<string, any> | null) {
  const providerSection = {
    subheader: 'Providers',
    roles: ['clinician', 'clinician_admin', 'clinician_self', 'clinician_full', 'supervisor'],
    items: [
      {
        title: 'Provider Profile',
        path: paths.dashboard.providerProfile,
        icon: ICONS.profile,
      },
      {
        title: 'Provider Caseload',
        path: paths.dashboard.providerCaseload,
        icon: ICONS.peopleOutline,
      },
    ],
  };

  if (clientReferralFlowEnabled) {
    providerSection.items.push({
      title: 'Client Referral Requests',
      path: paths.dashboard.referralRequestsProvider,
      icon: ICONS.editNote,
    });
  }

  const qualitySection = {
    title: 'Quality',
    icon: ICONS.dashboard,
    // @ts-ignore
    children: [
      {
        title: 'Missing Notes',
        path: paths.dashboard.analyticsMissingNotes,
      },
      {
        title: 'Treatment Plans',
        path: paths.dashboard.analyticsTreatmentPlans,
      },
    ],
  };

  // @ts-ignore
  providerSection.items.push(qualitySection);

  if (user && user.roles && user.roles.includes('supervisor')) {
    qualitySection.children.push({
      title: 'Supervisors',
      path: paths.dashboard.analyticsSupervisors,
    });
  }
  return providerSection;
}

function buildPracticeSection() {
  return {
    // @ts-ignore
    subheader: 'Practice',
    roles: ['practice_owner'],
    items: [
      {
        title: 'Profile',
        path: paths.dashboard.owner.practiceProfile,
        icon: ICONS.home,
      },
      {
        title: 'Provider Roster',
        path: paths.dashboard.owner.practiceProviderRoster,
        icon: ICONS.list,
      },
      {
        title: 'Client List',
        path: paths.dashboard.owner.practiceClientList,
        icon: ICONS.list,
      },
    ],
  };
}

export function useNavData() {
  const { user } = useAuthContext();

  return useMemo(() => {
    const sections = [
      {
        roles: ['practice_manager', 'supervisor', 'biller', 'scheduler', 'clinician_admin', 'clinician_full', 'clinician', 'clinician_self',],
        items: [
          {
            title: 'Meridian Home',
            path: paths.dashboard.root,
            icon: ICONS.home,
          },
        ],
      },
    ];

    if (adminFlowEnabled) {
      sections.push(buildAdminSection());
    }
    if (providerFlowEnabled) {
      sections.push(buildProviderSection(user));
    }
    if (practiceFlowEnabled) {
      sections.push(buildPracticeSection());
    }
    return sections;
  }, [user]);
}
